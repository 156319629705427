import React, { Component } from 'react';
import { connect } from 'react-redux';

const MARKETING_CONTACT_EMAIL = 'snait@df.com';

const B2B_DECK_URL =
  'https://static.diamondfoundry.com/portal/B2B_MEDIA_DECK.pdf';

class MarketingPage extends Component {
  render() {
    const { auth } = this.props;
    const { user } = auth;
    let apiKey = user.authentication_token;

    return (
      <main>
        <div className="digital-solutions-page main-wrapper padded">
          <div className="inner-wrapper">
            <div className="df-headline-container">
              <div className="df-headline">
                <h2>Marketing Materials</h2>
              </div>
            </div>

            <div>
              <p>
                Thank you for being a valued Diamond Foundry customer. Please
                refer to these{' '}
                <a target="_blank" href={B2B_DECK_URL}>
                  {' '}
                  downloadable decks{' '}
                </a>{' '}
                for educational materials about our brand, sustainability
                practices, and digital solutions.
              </p>
              <p>
                Please refer to this link to download digital assets for your
                website and social media:{' '}
                <a
                  target="_blank"
                  href="https://drive.google.com/drive/folders/12BgsNST_LYlVGHiUZRKh0L1NpAmr2p81"
                >
                  High Res Images + Logos
                </a>
              </p>
              <p>
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/IwJ7t0s5oxI"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </p>
              <p>
                For any other marketing related questions, please email
                <a href={`mailto:${MARKETING_CONTACT_EMAIL}`}>
                  &nbsp;{MARKETING_CONTACT_EMAIL}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(MarketingPage);
